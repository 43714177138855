import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import ErrorIcon from "../../assets/images/signup-error.svg"
import "../../styles/component/popup-signup.scss"
import FormBuilder from "./FormBuilder/Form-Builder"

function PopupFormBuilder(props) {
  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function() {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    loadScriptByURL(
      "captcha-container",
      `https://js.hcaptcha.com/1/api.js?render=explicit&recaptchacompat=off`,
      function() {
        // console.log("Script loaded!")
      }
    )
  }, [])

  return (
    <div
      className="popup-signup"
      style={{ display: !props.visibility && "none" }}
    >
      <div className="wrap position-fixed" role="button" tabIndex={0} />

      <Container className="position-fixed">
        <div className="overflow">
          <div
            className="close position-absolute"
            onClick={props.togglePopup}
            onKeyDown={props.togglePopup}
            role="button"
            tabIndex={0}
          >
            <img
              src="https://media.graphcms.com/zpyoXoSXRuWnSQ1KauLF"
              alt="close"
              height="24"
              width="24"
            />
          </div>

          <div className="forms">
            <div
              className={`form-captcha-error position-relative ${
                errorText
                  ? "d-flex justify-content-center align-items-center gap-16"
                  : "dspnone"
              }`}
            >
              <img
                src={ErrorIcon}
                height="10"
                width="10"
                className="form-error-icon position-relative"
                alt="img"
              />
              <p className="p14 err-text m-0">{errorText}</p>
            </div>

            <div className="grid-container subscribe">
              <div className="forms cn-cta">
                <FormBuilder
                  id="webinar-form"
                  formClassName="row"
                  errorClassName="error-message"
                  inputClassName="input-sm"
                  inputErrorClassName="error"
                  buttonText="REGISTER NOW"
                  buttonClassName="primary"
                  data={props.formdata}
                  IpStackData
                  formName="Webinar - Superops"
                  endpoint={process.env.HUBSPOT_WEBINAR_REGISTER_API_ENDPOINT}
                  url={props.pageURL}
                  path={props.path ? props.path : null}
                  afterSubmit={props.handleAfterSubmit}
                  ErrorTextSetter={val => setErrorText(val)}
                >
                  <div className="p12 mt20 text-center">
                    <p>
                      By clicking "REGISTER NOW", you agree to SuperOps' <br />
                      <a className="tdnone text-pink" href="/privacy">
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a className="tdnone text-pink" href="/privacy">
                        Privacy Policy.
                      </a>
                    </p>
                  </div>
                </FormBuilder>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopupFormBuilder
