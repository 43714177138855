import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import CarouselComponent from "../common/carousel-component"

function FeaturedTopics(props) {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  const responsive = {
    desktopLarge: {
      breakpoint: { max: 3000, min: 1401 },
      items: 4,
    },

    desktopMedium: {
      breakpoint: { max: 1400, min: 1200 },
      items: 3.4,
    },
    desktopSmall: {
      breakpoint: { max: 1199, min: 992 },
      items: 3.3,
    },

    tablet: {
      breakpoint: { max: 992, min: 767 },
      items: 2.3,
    },
    mobile: {
      breakpoint: { max: 767, min: 600 },
      items: 1.8,
    },

    mobile1: {
      breakpoint: { max: 600, min: 539 },
      items: 1.4,
      partialVisibilityGutter: 75,
    },
    mobile2: {
      breakpoint: { max: 539, min: 500 },
      items: 1.2,
      partialVisibilityGutter: 75,
    },
    mobile3: {
      breakpoint: { max: 500, min: 400 },
      items: 1,
      partialVisibilityGutter: 75,
    },
    mobileSmall: {
      breakpoint: { max: 400, min: 372 },
      items: 0.88,
      partialVisibilityGutter: 75,
    },
    mobileS: {
      breakpoint: { max: 372, min: 0 },
      items: 0.5,
      partialVisibilityGutter: 75,
    },
  }

  return (
    <CarouselComponent
      responsive={props.responsive ? props.responsive : responsive}
      swipeable
      partialVisbile
      status={props.status}
      statusTextRight={props.statusTextRight}
    >
      {props.FeaturedTopics.map((w, i) => {
        return (
          <Fade
            {...(screenWidth >= 992 && {
              bottom: true,
            })}
            delay={i * 100}
          >
            <div
              className="crd position-relative"
              style={{ background: props.FeaturedTopicsBg[i] }}
            >
              <div className="alt">
                <h2 className="fw-bold"> {"0" + (i + 1)} </h2>
              </div>
              <span className="p16">
                <p>{w.html ? parse(w.html) : w}</p>
              </span>
            </div>
          </Fade>
        )
      })}
    </CarouselComponent>
  )
}

export default FeaturedTopics
