import React from "react"
// import Slide from "../../components/utility/slide_content"

const webinarCard = props => {
  return (
    <>
    <div className="about-card">
    {/* <Slide style={{ "--sal-delay": "0.2s" }}> */}
      <a className="no-text-decoration">
        <div className="card-wrap" style={props.style}>
          {!!props.coverImage && (
            <div
              className="cover"
              style={{
                height: !!props.coverImageHeight && props.coverImageHeight,
              }}
            >
              <img
                src={props.coverImage.url}
                alt="Cover"
                height="100"
                width="100"
              />
            </div>
          )}

          <div
            className={`content position-relative ${!!props.cardSize &&
              `content-${props.cardSize}`}`}
            style={{ minHeight: props.contentMinHeight , borderTop: props.coverImage && "none",
             borderRadius: !props.coverImage && "5px", background: props.background}}
          >
            {!!props.tags && (
              <div className="title">
                {props.tags.map((tag, tagIndex) => {
                  return (
                    <>
                      {/* <a href={`/blog/categories/${tag.toLowerCase()}`} className="tdnone"> */}
                        <span className="p14">
                          {" "}
                          <p>
                            {" "}
                            {tag.toLowerCase()}{" "}
                          </p>{" "}
                        </span>
                        <p
                          className="line"
                          style={{
                            display: tagIndex === props.tags.length - 1 && "none",
                          }}
                        >
                          {" "}
                          |{" "}
                        </p>
                      {/* </a> */}
                    </>
                  )
                })}
              </div>
            )}
            
            <div className="details">
              {props.cardSize === "small" ? (<h4
                    className={
                      !!props.headingEllipseLine &&
                      `el-${props.headingEllipseLine}`
                    }
                    style={{ marginTop: !!props.tags ? "16px" : "-4px", fontSize: props.headingFontSize }}
                    title={props.title}
                  >
                    {" "}
                    {props.title}{" "}
                  </h4>) : 
                  (<h6
                    className={
                      !!props.headingEllipseLine &&
                      `el-${props.headingEllipseLine}`
                    }
                    style={{ marginTop: !!props.tags ? "16px" : "-4px", fontSize: props.headingFontSize }}
                    title={props.title}
                  >
                    {" "}
                    {props.title}{" "}
                  </h6>)}
                  {props.subtitle && <p className="p14 text-light-grey">{props.subtitle}</p>}
              {!!props.excerpt && (
                <span className="p14">
                  <p
                    className={
                      !!props.contentEllipseLine &&
                      `el-${props.contentEllipseLine}`
                    }
                    title={props.excerpt}
                  >
                    {" "}
                    {props.excerpt}{" "}
                  </p>
                </span>
              )}
            </div>
          </div>
        </div>
      </a>
    {/* </Slide> */}

    </div>

</>
  )
}

export default webinarCard
