import React from "react"

import "../../styles/component/pulsating-circle.scss"

const PulsatingRing = () => (
  <>
    <div className="pulsating-circle"></div>
  </>
)

export default PulsatingRing
